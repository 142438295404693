import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export default function bookingDetails(bookingId, data, payMethod) {
  // console.log(data);

  const _in = new Date(data.check.in)
  const _out = new Date(data.check.out)
  // console.log(_in, _out);

  const cbook = data.cartData.cart.map((cart, index) => {
    return {
      id: bookingId,
      code: cart.code,
      uid: nanoid(32) + "@heavenuponricefields.com",
      start: new Date(dayjs(data.check.in).format("YYYY-MM-DD")),
      end: new Date(dayjs(data.check.out).format("YYYY-MM-DD")),
      title: `Booked`,
      description: `CHECKIN: ${data.check.in.format(
        "YYYY-MM-DD"
      )}\nCHECKOUT: ${data.check.out.format("YYYY-MM-DD")}\nNIGHTS: ${
        data.check.nights
      }\nNAME: ${data.cinfo.title}. ${data.cinfo.fname} ${data.cinfo.lname}`,
    };
  });

  const room = data.cartData.cart.map((cart, index) => {
    let pkg = "";

    switch (cart.packageTitle) {
      case "Room Only":
        pkg = "RO";
        break;
      case "Breakfast Included":
        pkg = "BI";
        break;
      case "Breakfast + Dinner Included":
        pkg = "BD";
        break;
    }

    return {
      code: cart.code,
      package: pkg,
      adults: cart.adults,
      children: cart.children,
    };
  });

  const book = {
    uid: nanoid(64) + '@hurf',
    id: bookingId,
    adults: data.check.adults,
    children: data.check.children,
    room,
    status: "Booked",
    in: new Date(dayjs(data.check.in).format("YYYY-MM-DD")),
    out: new Date(dayjs(data.check.out).format("YYYY-MM-DD")),
    nights: data.check.nights,
    info: {
      name: `${data.cinfo.title}. ${data.cinfo.fname} ${data.cinfo.lname}`,
      email: data.cinfo.email,
      phone: data.cinfo.number,
    },
    price: {
      total: Number(data.totalPrice.total.toFixed(2)),
      paid: payMethod ? Number(data.totalPrice.total.toFixed(2)) : 0,
    },
    created: new Date(),
  };

  return { book, cbook };
}
