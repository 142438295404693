import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material'
import './style.css'
import { nanoid } from '@reduxjs/toolkit'
import axios from 'axios'
import CheckDiscount from './CheckDiscount'
import CheckOutTotal from './CheckOutTotal'
import CheckOutForm from './CheckOutForm'
import { useEffect, useReducer, useRef, useState } from 'react'
import Payment from '../Payment/Payment'
import CheckBookCpt from '../Payment/CheckBookCpt'
import CardList from './CardList'


const CheckOut = ({ methods, cartData, check, packagesprices }) => {

  // console.log(cartData.adutls, cartData, check);

  check = {
    ...check,
    adults: cartData.adults,
    children: cartData.children
  }


  const [payMethod, setPayMethod] = useState('now')
  const [bookPage, setBookPage] = useState(false)
  const [cartDataLast, setCartDataLast] = useState(cartData)
  const [showBack, setShowBack] = useState(true)

  const cinforReducer = (state, action) => {

    if (action.type === 'change') {
      return action.info
    }

    return state
  }

  const totalPriceReducer = (state, action) => {

    if (action.type === 'change') {
      return action.data
    }

    return state
  }

  const [cinfo, cinfoDispatch] = useReducer(cinforReducer, {
    title: '',
    fname: '',
    lname: '',
    email: '',
    number: '',
    county: '',
    language: '',
    message: ''
  })

  const [totalPrice, totalPriceDispatch] = useReducer(totalPriceReducer, {
    total: 0,
    totalObj: ''
  })

  const [discouttax, discouttaxDispatch] = useReducer((state, action) => {
    if (action.type === 'set') {
      return action.data
    }
    return state
  }, [])

  // console.log(cinfo);

  // const cbook = {
  //   code: [302],
  //   uid: nanoid(32) + '@heavenuponricefields.com',
  //   start: new Date('2023-09-25'),
  //   end: new Date('2023-09-028'),
  //   title: 'Arjan Kuijper (1134)',
  //   description: '2022-03-20\nCHECKOUT: 2022-03-22\nNIGHTS: 2\nPROPERTY: Deluxe Double Room with Balcony &#038; Rice Paddy View 202\n',
  // }

  // console.log(cartData, new Date(check.in.format()), new Date(check.out.format()));

  const cbook = {
    cart: [],
    uid: nanoid(32) + '@heavenuponricefields.com',
    start: new Date(check.in.format()),
    end: new Date(check.out.format()),
    title: 'Arjan Kuijper (1134)',
    description: check.in.format('YYYY-MM-DD') + '\nCHECKOUT: ' + check.out.format('YYYY-MM-DD') + '\nPROPERTY: Deluxe Double Room with Balcony &#038; Rice Paddy View 202\n',
  }

  function submitHandler(e) {
    e.preventDefault();

    // console.log(cinfo);

    if(cinfo.country === '') alert('Something goes wrong with country? select again..!')
    else setBookPage(true)
  }



  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  return (
    <div className='checkout-page notranslate'>
      <div className="contact-checkout-box">
        <div className="contact-title-box ">
          <Typography variant='h4'>Place Order</Typography>
        </div>

        {/* <CheckBookCpt /> */}

        {
          bookPage
            ?
            <div>
              {showBack ? <Button style={{ marginBottom: '1px' }} onClick={() => setBookPage(false)}>Go Back</Button> : ''}              
              <Payment setShowBack={setShowBack} payMethod={payMethod} totalPrice={totalPrice} cinfo={cinfo} cartData={cartData} check={check} discouttax={discouttax} />
            </div>
            :
            <form onSubmit={submitHandler}>

              <Button style={{ marginBottom: '1px' }} onClick={() => methods.setMode(true)} >Go Back</Button>
              <Stack className="checkout-box" direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'start' }}>

                <div className="checkout-cart-form-list">

                  <CardList cartData={cartData} check={check} cartDataLast={cartDataLast} setCartDataLast={setCartDataLast} packagesprices={packagesprices} />

                  <div className="checkout-cart-form ">
                    <Typography variant='h5' style={{ margin: "10px 0 0 10px" }}> Customer Info </Typography>
                    <CheckOutForm cinfo={cinfo} cinfoDispatch={cinfoDispatch} />
                  </div>

                </div>

                <div className="checkout-out">

                  <Typography variant='h5'> Total </Typography>
                  <CheckOutTotal cartData={cartDataLast} check={check} totalPriceDispatch={totalPriceDispatch} discouttaxDispatch={discouttaxDispatch} />

                  <hr style={{ marginBottom: '20px' }} />
                  <Typography variant='h5'> Select your payment method</Typography>

                  <div className='checkout-out-box'>
                    <div style={{ margin: '0px 20px 0 20px' }}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={payMethod}
                          onChange={(e) => setPayMethod(e.target.defaultValue)}
                        >
                          <p style={{ padding: '10px 0 20px 0' }}>  See the documentation below for a complete refe components mentioned here. </p>
                          <FormControlLabel value="now" control={<Radio value="now" />} label="Pay Now" />
                          <FormControlLabel value="later" control={<Radio value="later" />} label="Pay Later" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className='checkout-out-box-btn'>
                      <Button type='submit' variant='contained'>NEXT</Button>
                    </div>
                  </div>
                </div>
              </Stack>
            </form>
        }
      </div>
    </div>
  )
}

export default CheckOut
